<script>
    export default {
        props: ['advertisementData'],
        data() {
            return {
                showModal: false,
            };
        },
        methods: {

            closeModal() {
                this.showModal = false;
            },
        }
    };
</script>



<template>
    <b-modal id="view_advertisement" size="xl" v-model="showModal" title="View Advertisement" title-class="font-18">
        <div class="row mb-3" v-if="advertisementData">
            <div class="col-sm-6">
                <p class="font-size-16 mb-2"><span class="text-strong">Advertisement ID:</span> {{advertisementData.placement_id}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Type:</span> {{advertisementData.placement_name}} ({{advertisementData.placement_type_short}})</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Active: <span v-html="format_status(advertisementData.placement_active ? 'yes' : 'no')"></span></span> </p>
                <p class="font-size-16 mb-2"><span class="text-strong">Created:</span> {{advertisementData.placement_timestamp}}</p>
            </div>
            <div class="col-sm-6">
                <p class="font-size-16 mb-2 text-strong">Advertisement Attributes</p>
                <div v-for="(value, key) in advertisementData.attributes" :key="key">
                    {{ key }}: {{ value }}
                </div>
            </div>

            <hr>

            <div class="row mt-3">
                <div class="col-sm-12">
                    <h5>Advertisement Media</h5>
                   <div class="table-responsive">
                       <table class="table mt-3">
                           <thead>
                           <th>Media ID</th>
                           <th>Media Type</th>
                           <th>Image</th>
                           <th>Width</th>
                           <th>Height</th>
                           <th>Attributes</th>
                           <th>Text</th>
                           <th>Created</th>
                           </thead>
                           <tbody>
                           <tr v-for="item in advertisementData.media" :key="item.id">
                               <td>{{item.id}}</td>
                               <td>{{item.type}}</td>
                               <td>
                                   <div class="flex-shrink-0 ">
                                       <a :title="item.attributes.text" :href="item.source_url" target="_blank"><img class="img-fluid img-thumbnail table-images" :src="item.source_url"></a>
                                   </div>
                               </td>
                               <td>{{item.width}}</td>
                               <td>{{item.height}}</td>
                               <td>
                                   <div v-for="(value, key) in item.attributes" :key="key">
                                       <span v-if="key != 'width' && key != 'height' && key != 'text' && key != 'source_url'">{{ key }}: {{ value }}</span>
                                   </div>
                               </td>
                               <td>{{item.attributes.text}}</td>
                               <td>{{item.timestamp}}</td>
                           </tr>
                           </tbody>
                       </table>
                   </div>
                </div>
            </div>
        </div>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
        </template>
    </b-modal>

</template>