<script>
    import Advertising from '@/services/Advertising'

    export default {
        props: {
            sponsor_id: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                showModal: false,
                tryingToSubmit: false,
                source_url: '',
                text: '',
                file: '',
                media: '',
                image_removed: false,
                toggleActive: 1,
                csrf_token: localStorage.getItem('csrf_token')
            };
        },

        methods: {

            closeModal() {
                this.showModal = false;
                this.source_url = '';
                this.file = '';
                this.text = '';
                this.media = '';
                this.image_removed = false;
            },

            addSponsorMedia(){
                this.tryingToSubmit = true;

                let formData = new FormData();
                formData.append('file', this.file)
                formData.append('source_url', this.source_url);
                formData.append('text', this.text);
                formData.append('csrf_token', this.csrf_token);

                Advertising.addSponsoredMedia(this.sponsor_id, formData)
                .then((response) => {
                    this.tryingToSubmit = false
                    const res = response.data.id ? response.data.id : false
                    const error = response.error ? response.error : 'Failed'
                    if(res){
                        this.$emit('refreshScreen')
                        this.successmsg("Sponsor Media Added")
                        this.closeModal()
                    }else{
                        this.failedmsg("Failed to Add Sponsor Media")
                    }
                })
                .catch(error => {
                    const errorMsg = error.response.data.error ? error.response.data.error : 'Failed'
                    this.tryingToSubmit = false
                    this.failedmsg(errorMsg)
                });
            },

            selectFile(event){
                this.file = event.target.files[0]
                this.media = URL.createObjectURL(this.file)
            },

            removeImage(){
                this.media = ''
                this.file = ''
                this.$refs['file-input'].reset()
                this.image_removed = true
            },
        }
    }
</script>


<template>
    <b-modal id="add_sponsor_media" v-model="showModal" title="Add New Sponsor Media" title-class="text-black font-18" centered>
    <form @submit.prevent="addSponsorMedia">
        <div class="row">
          <div class="col-12">

              <b-form-checkbox-group >
                  <div class="form-check form-switch form-switch-lg mb-3" >
                      <input v-model="toggleActive"
                             type="checkbox"
                             class="form-check-input"
                             id="switch1"
                             true-value="1"
                             false-value="0"
                      />
                      <label class="form-check-label" for="switch1">{{toggleActive==1 ? 'File Upload' : 'Enter File URL' }}</label>
                  </div>
              </b-form-checkbox-group>

                <div class="mb-3" v-if="toggleActive==1">
                    <b-form-group label-for="image" class="mb-3 text-center image-area">
                        <img :src="media != '' ? media : require('@/assets/images/image_not_found.png')" alt="" width="200" class="img-thumbnail me-2" >
                        <b-form-file @change="selectFile" ref="file-input" type="file" class="mt-3" plain accept="image/jpeg, image/png, image/gif" ></b-form-file>
                        <a v-if="media" class="remove-image" href="javascript:void(0);" @click="removeImage" style="display: inline;">&#215;</a>
                    </b-form-group>
                </div>

              <b-form-group label="Source URL" label-for="source_url" class="mb-3" v-if="toggleActive==0">
                  <b-form-input
                          v-model.trim="source_url"
                          type="text"
                          id="source_url"
                          aria-describedby="source_url-feedback"
                  >
                  </b-form-input>
              </b-form-group>

              <b-form-group label="Media Text" label-for="media_text" class="mb-3">
                  <b-form-input
                          v-model.trim="text"
                          type="text"
                          id="media_text"
                          aria-describedby="media_text-feedback"
                  >
                  </b-form-input>
              </b-form-group>

            </div>
        </div>
        <input type="hidden" name="csrf_token" v-model="csrf_token">
      </form>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addSponsorMedia" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
 
</template>